<template>
  <b-modal
    id="add-vacancy-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    title="Добавление вакансии"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-1">
        <div>
          <b-form-group
            label="Название вакансии"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="vacancy.name"
              v-validate="'required'"
              name="name"
            />
            <small
              v-show="errors.has('name')"
              class="text-danger"
            >{{ errors.first('name') }}</small>
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Зарплата"
            label-for="salary"
          >
            <b-form-input
              id="salary"
              v-model="vacancy.salary"
              name="salary"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Опыт работы"
            label-for="employment_experience"
          >
            <b-form-input
              id="employment_experience"
              v-model="vacancy.employment_experience"
              name="employment_experience"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="График работы"
            label-for="employment_schedule"
          >
            <b-form-input
              id="employment_schedule"
              v-model="vacancy.employment_schedule"
              name="employment_schedule"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Тип занятости"
            label-for="employment_type"
          >
            <b-form-input
              id="employment_type"
              v-model="vacancy.employment_type"
              name="employment_type"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Локации вакансии"
            label-for="contacts"
          >
            <Select2
              id="contacts"
              v-model="vacancy.contacts"
              :options="contactsOptions"
              :settings="{multiple: true}"
              name="contacts"
            />
            <small
              v-show="errors.has('brands')"
              class="text-danger"
            >
              {{ errors.first('brands') }}
            </small>
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Описание"
            label-for="content_template"
          >
            <CKEditor
              v-model="vacancy.text"
              name="content"
            />
          </b-form-group>
        </div>
        <div>
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="vacancy.is_hot"
              switch
            />
            <div>
              Срочная вакансия
            </div>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {
  VBTooltip, BFormCheckbox, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import Select2 from 'v-select2-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CKEditor from 'ckeditor4-vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'

import Contact from '@/api/http/models/contact/Contact'
import Vacancy from '@/api/http/models/vacancy/Vacancy'

export default {
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    CKEditor: CKEditor.component,
    Select2,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [mixinErrorsParse],

  props: {
    title: {
      type: String,
      default: 'Добавление вакансии',
    },
  },

  data() {
    return {
      visible: false,
      vacancy: {
        name: '',
        salary: '',
        employment_experience: '',
        employment_schedule: '',
        employment_type: '',
        text: '',
        is_hot: false,
        contacts: [],
      },
      contactsOptions: [],
    }
  },

  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },

  async created() {
    const responseContacts = await Contact.getAll(this.currentSite.slug)
    if (responseContacts.data) {
      this.contactsOptions = responseContacts.data.map(contact => ({
        id: contact.id,
        text: `${contact.city} ${contact.address}`,
      }))
    }
    this.visible = true
  },

  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Vacancy.add(this.currentSite.slug, this.vacancy)

        if (response.status === 'success' && response.data) {
          this.$emit('added', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Вакансия добавлена',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('add-vacancy-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при добавлении вакансии',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }

        this.$nextTick(() => {
          this.$bvModal.hide('page-edit-popup')
        })
      }
    },
  },
}
</script>
