<template>
  <div>
    <div class="accordion-label__content">
      <div class="row align-items-center w-100">
        <div class="col-lg-4">
          Название вакансии
        </div>
        <div class="col-lg-4">
          Зарплата
        </div>
        <div class="col-lg-4 text-right">
          Действия
        </div>
      </div>
    </div>
    <div
      class="accordion"
      role="tablist"
    >
      <div
        v-for="vacancy in vacancies"
        :key="vacancy.id"
        class="accordion__item"
      >
        <div class="accordion-label accordion-label--disable-arrow">
          <div class="accordion-label__content">
            <div class="row align-items-center w-100">
              <div class="col-12 col-lg-4">
                {{ vacancy.name }}
              </div>
              <div class="col-12 col-lg-4">
                {{ vacancy.salary }}
              </div>
              <div class="col-12 col-lg-4">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.prevent="openEditPopup(vacancy)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="12"
                    />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    @click.prevent="openDeletePopup(vacancy)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-vacancy-popup
      v-if="showEditPopup && currentVacancyId"
      :key="currentVacancyId"
      :vacancy-id="currentVacancyId"
      @updated="$emit('updated', $event)"
      @hidden="hideEditPopup"
    />
    <delete-vacancy-popup
      v-if="showDeletePopup && currentVacancyId"
      :key="currentVacancyId"
      :vacancy-id="currentVacancyId"
      @deleted="$emit('deleted', $event)"
      @hidden="hideDeletePopup"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton } from 'bootstrap-vue'
import EditVacancyPopup from './EditVacancyPopup.vue'
import DeleteVacancyPopup from './DeleteVacancyPopup.vue'

export default {
  components: {
    BButton,
    EditVacancyPopup,
    DeleteVacancyPopup,
  },
  props: {
    vacancies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showEditPopup: false,
      showDeletePopup: false,
      currentVacancyId: null,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  methods: {
    openEditPopup(vacancy) {
      this.showEditPopup = true
      this.currentVacancyId = vacancy.id
    },
    hideEditPopup() {
      this.showEditPopup = false
      this.currentVacancyId = null
    },
    openDeletePopup(vacancy) {
      this.showDeletePopup = true
      this.currentVacancyId = vacancy.id
    },
    hideDeletePopup() {
      this.showDeletePopup = false
      this.currentVacancyId = null
    },
  },
}
</script>
