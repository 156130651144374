import HTTPService from '@/api/http/HTTPService'
import VacancyInterface from './VacancyInterface'
import VacancySortInterface from './VacancySortInterface'

export default {
  getAll: (siteSlug: string) => HTTPService.get(`site/${siteSlug}/vacancy`),
  add: (siteSlug: string, payload: VacancyInterface) => HTTPService.post(`site/${siteSlug}/vacancy`, payload),
  getOne: (siteSlug: string, vacancyPage: string) => HTTPService.get(`site/${siteSlug}/vacancy/${vacancyPage}`),
  update: (siteSlug: string, vacancyPage: string, payload: VacancyInterface) => HTTPService.put(`site/${siteSlug}/vacancy/${vacancyPage}`, payload),
  delete: (siteSlug: string, vacancyPage: string) => HTTPService.delete(`site/${siteSlug}/vacancy/${vacancyPage}`),
  sort: (siteSlug: string, payload: VacancySortInterface) => HTTPService.post(`site/${siteSlug}/vacancy/reorder`, payload),
}
