<template>
  <b-card>
    <div class="row mb-2">
      <div class="col">
        <b-button
          class="d-flex align-items-center"
          variant="primary"
          @click.prevent="showAddPopup = true"
        >
          <feather-icon
            icon="PlusIcon"
            size="18"
            class="mr-1"
          />
          Добавить вакансию
        </b-button>
      </div>
      <div class="col d-flex justify-content-end">
        <b-button
          class="d-inline-flex align-items-center"
          variant="primary"
          :to="{name: 'vacancies_sort'}"
        >
          <feather-icon
            icon="GridIcon"
            size="18"
            class="mr-1"
          />
          Сортировка
        </b-button>
      </div>
    </div>
    <div
      v-if="vacancies.length"
    >
      <vacancy-list
        :vacancies="vacancies"
        @updated="updateVacancies"
        @deleted="deleteVacancy"
      />
    </div>
    <div v-else>
      Нет вакансий
    </div>
    <add-vacancy-popup
      v-if="showAddPopup"
      @added="addVacancy"
      @hidden="showAddPopup = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BButton,
} from 'bootstrap-vue'

import Vacancy from '@/api/http/models/vacancy/Vacancy'

import AddVacancyPopup from './AddVacancyPopup.vue'
import VacancyList from './VacancyList.vue'

export default {
  components: {
    BCard,
    BButton,
    VacancyList,
    AddVacancyPopup,
  },
  data() {
    return {
      showAddPopup: false,
      vacancies: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    await this.fetchVacancies()
  },
  methods: {
    async fetchVacancies() {
      const response = await Vacancy.getAll(this.currentSite.slug)

      this.vacancies = response.data || []
    },
    addVacancy(vacancy) {
      this.vacancies.push(vacancy)
    },
    updateVacancies(vacancy) {
      const index = this.vacancies.findIndex(cur => cur.id === vacancy.id)

      if (index !== -1) {
        this.vacancies.splice(index, 1, vacancy)
      }
    },
    deleteVacancy(vacancy) {
      const index = this.vacancies.findIndex(cur => cur.id === vacancy.id)

      if (index !== -1) {
        this.vacancies.splice(index, 1)
      }
    },
  },
}
</script>
